@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700&display=swap');

@layer base {
  ul {
    @apply list-disc list-outside;
  }
  p {
    @apply mt-4 leading-6;
  }
}