/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

html{
  display: flex;
  flex-flow: row nowrap;  
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 100%;
  max-width: 820px;
  -webkit-box-shadow: 0px 0px 96px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 96px 1px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 96px 1px rgba(0,0,0,0.75);
}

ion-header {
  ion-toolbar {
    --background: var(--ion-color-medium);
    --color: black;
    --min-height: 60px;
    ion-title {
      font-size: 22px;
      padding-left: 70px;
      padding-right: 70px;
      svg {
        fill: black;
      }
    }
    ion-buttons {
      padding-right: 8px;
      ion-button {
        color: black;
      }
    }
    ion-icon {
      color: black;
    }
    ion-menu-button {
      --color: var(--ion-color-primary);
    }
    ion-badge {
      --color: lightgreen;
      --background: white;
    }
  }
}

.footer-button {
  text-align: -webkit-center;
  // background-color: var(--chea-lightgray);
  ion-button {
      font-size: 20px;
      width: 50%;
      height: 54px;
      margin-top: 12px;
      margin-bottom: 12px;
      --background: var(--chea-purple);
  }
}
    
// Must be global, not in component, to control circle color
.chea-mat-spinner {
  margin: 30% auto 10px auto;
  circle {
    stroke: #c94b97;
  }
}

.save-spinner {
  circle {
    stroke: white;
  }
}

.entry-card {
  margin-top: 15px;
  margin-bottom: 5px;
  background-color: var(--ion-color-primary-contrast);
  min-height: 120px;
  max-width: 400px;
  box-shadow: none;
  ion-card-header {
    padding: 8px;
  }
  ion-card-content {
    padding: 8px;
  }
  ion-item {
    --background: var(--ion-color-primary-contrast);
    padding: 0px;
    --padding-start: 0px;   // controls the extra space at the start of a sliding item
  }
  ion-item-sliding {
    width: 100%;
    padding: 0px;
  }
  ion-grid {
    min-height: 70px;
  }
  ion-row {
    align-items: center;
    justify-content: start;
  }
  ion-col {
    padding: 0;
  }
  .cardtitle {
    padding: 0;
    min-height: 48px;
    max-height: 78px;
    font-size: 28px;
    // white-space: normal;
    // overflow: hidden;
    // text-overflow: ellipsis;
    
  }
  .cardname {
    font-size: 18px;
    font-weight: bold;
    color: var(--ion-color-primary);
    height: 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
  }
  .description {
    font-size: 14px;
    font-weight: normal;
    color: var(--ion-color-primary);
    height: 40px;
    // white-space: normal;
    // overflow: hidden;
    // text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    // color: var(--chea-sea-blue);
  }
  .more-menu {
    align-self: start;
    text-align: right;
    height: 40px;
  }
  .favorite-col {
    text-align: right;
    align-self: end;
    ion-badge {
      --color: black;
      --background: var(--ion-color-medium);
      opacity: 0.6;
      vertical-align: top;
    }
    .action-count {
      display: inline-block;
      vertical-align: bottom;
      margin: 12px 8px 12px 16px; 
      font-size: 18px;
    }
  }
  .timestamp {
      padding-left: 0px;
      padding-top: 5px;
      align-items: flex-end;
      font-size: 12px;
      color: var(--ion-color-primary-shade)
  }
  .entrytype {
      font-size: 12px;
      text-align: right;
      // color: var(--chea-blue)    
  }
  .actionbutton {
    margin: 0 2 0 2;
    --background: var(--ion-color-primary-contrast);
    --color: var(--ion-color-primary);
  }
}

.series-intro-modal {
  --height: 72%;
  --width: 80%;
  --background: white;
}

.global-modal {
  --height: 70%;
  --width: 85%;
  --background: white;
}

// See https://github.com/ionic-team/ionic-framework/issues/17971#issuecomment-521584215
// .backdrop-no-scroll {
//   ion-content:not([scrollable]) {
//     --overflow: hidden;
//   }
// }

ion-checkbox {
  --border-radius: 0px;
  --checkbox-background-checked: white;
  --checkmark-color: black;
}

ion-fab {
  ion-fab-button {
      --background: var(--chea-purple)
  }
}
.fab-menu-button {
  font-size: 22px !important;
}

// CSS specific to iOS devices
@supports (-webkit-touch-callout: none) {
  // This media query is for: iPhone 12 Mini, iPhone 11 Pro, iPhone Xs, and iPhone X
  @media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 
      ion-fab {
        bottom: 40px
      }
  }
  // This media query is for: iPhone XS Max and XR, iPhone 11, IPhone 11 Pro Max
  @media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) {
      ion-fab {
        bottom: 40px
      }
    }
  // This media query is for: iPhone 12 and iPhone 12 Pro
  @media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3) { 
      ion-fab {
        bottom: 40px
      }
    }
  // This media query is for: iPhone 12 Pro Max
  @media only screen 
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) { 
      ion-fab {
        bottom: 40px
      }
    }
}

ion-item-options { 
  border-width: 0 !important;
  ion-item-option {
    padding: 8px 0;
    align-self: center;
    font-size: 14px;
    text-align: center;
    min-height: 40px;
    max-height: 110px;
    margin: 4px;
    min-width: 65px;
    max-width: 100px;
    color: black;
    background-color: var(--chea-vellum);
    border: 1px solid var(--ion-background-color);
    border-radius: 8px;
    font-weight: bold;
  }
}

.statement-text {
  background-color: white;
  font-size: 18px;
  padding: 12px;
  margin-bottom: 8px;
  // box-shadow: 3px 3px 5px #b1b0b0;
  border-radius: 15px;
  user-select: auto;
  img {
    vertical-align: middle;
  }
  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
}